import { graphql, useStaticQuery } from 'gatsby'

export const useImageFiles = () => {
  const { imageFiles } = useStaticQuery(
    graphql`
      query {
        imageFiles: allFile(
          filter: { sourceInstanceName: { eq: "images" }, extension: { regex: "/png|jpg/" } }
          limit: 9999
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    `
  )
  return imageFiles
}
