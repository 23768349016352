import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { isMobileOnly } from 'react-device-detect'

import Container from 'react-bootstrap/Container'

import BackgroundImage from './background-image'

import './section.scss'

interface Props {
  children: React.ReactNode
  backgroundImage: string
  backgroundStyle: string
  className: string
  variant: string
}

const Section = ({ children, backgroundImage, backgroundStyle, className, variant }: Props) => {
  const classes = ['section', className, variant]
  const [mobileDetected, setMobileDetected] = useState(false)
  useEffect(() => {
    setMobileDetected(isMobileOnly)
  }, [])

  let contents
  if (!mobileDetected && backgroundImage?.length > 0) {
    contents = (
      <div className="section-inner">
        <BackgroundImage fileName={backgroundImage} />
        <Container fluid="xl">{children}</Container>
      </div>
    )
  } else {
    if (backgroundStyle?.length > 0) {
      classes.push(`bg-${backgroundStyle}`)
    }
    contents = <Container fluid="xl">{children}</Container>
  }
  return <section className={classNames(classes)}>{contents}</section>
}

export default Section
